import axios from '@axios'

export default function lessonsServices() {
  const getLessons = async (queryParams) => {
    const res = await axios.get('/lessons', {
      params: queryParams,
    })
    console.log(res)
    return res
  }

  const create = async (queryParams) => {
    const res = await axios.post('/lesson/create', queryParams)
    return res
  }

  const edit = async (lesson_id, queryParams) => {
    const res = await axios.post(`/lesson/update/${lesson_id}`, queryParams)
    return res
  }

  const upload_record = async (lesson_id, queryParams) => {
    const res = await axios.post('/lesson/upload_record', queryParams)
    return res
  }

  const del = async (queryParams) => {
    const res = await axios.post('/lesson/delete', queryParams)
    return res
  }

  return {
    getLessons,
    create,
    edit,
    del,
    upload_record,
  }
}
